<template>
  <div style="padding-top: 85px;">
    <div class="demand-detail">
      <div class="s-title">
        <span class="title">{{projectInfo.bt}}</span>
        <span class="goback" @click="goback">返回 ></span>
      </div>
      <p class="demand">需求编号：{{projectInfo.xqbh}} 更新时间：{{projectInfo.cjsj}}</p>
      <div class="detail">
        <div class="left">
          <div class="item-con">
            <div class="item">
              <p class="key">地区</p>
              <!-- <p>{{projectInfo.sm}}/{{projectInfo.xq}}/{{projectInfo.xz}}</p> -->
              <p>{{region}}</p>
            </div>
            <div class="item">
              <span style="width: 173px;">
                <p class="key">产权类型</p>
                <p>{{type}}</p>
              </span>
              <span>
                <p class="key">面积</p>
                <p>{{projectInfo.zxmj}}-{{projectInfo.zdmj}}亩</p>
              </span>
            </div>
            <div class="item">
              <span style="width: 173px;">
                <p class="key">流转年限</p>
                <p>{{projectInfo.lznx}}年</p>
              </span>
              <span>
                <p class="key">流转类型</p>
                <p>{{projectInfo.lzlx}}</p>
              </span>
            </div>
          </div>
          <div class="item-con">
            <div class="item" style="display: flex;">
              <span class="key" style="width: 108px;">期望价格</span>
              <span><span style="color: #fb612b;font-size: 22px;">{{projectInfo.zxqwjg}}-{{projectInfo.zdqwjg}}</span> 元/年/亩</span>
            </div>
            <div class="item">
              <span class="key" style="width: 108px;">联系方式</span>
              <span style="color: #00a0e9;cursor:pointer;" @click="showPhone">立即查看联系方式</span>
            </div>
            <div class="item">
              <el-button type="primary" plain>预约回电</el-button>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="s-title">
            <span class="title">相似需求推荐</span>
          </div>
          <div class="docs">
          <div class="docs-item" v-for="(item, index) in suggests" :key="index">
            <span>
              <img src="imgs/icon-decoration.png" alt="">
              <span>{{item.name}}</span>
            </span>
          </div>
        </div>
        </div>
      </div>
      <div class="nearby">
        <div class="s-title">
          <span class="title">附近地区成交产权</span>
        </div>
        <el-table
          border
          class="table"
          :data="tableData"
          style="width: 881px;"
          :row-class-name="handleRowClass">
          <el-table-column align="center" prop="name" label="地块信息" width="260px"></el-table-column>
          <el-table-column align="center" prop="type" label="流转方式"></el-table-column>
          <el-table-column align="center" prop="area" label="面积"></el-table-column>
          <el-table-column align="center" prop="price" label="价格"></el-table-column>
        </el-table>
      </div>
    </div>

    <el-dialog
      title="联系方式"
      style="text-align:left;"
      :visible.sync="dialogVisible"
      width="25%">
      <p>联系人：{{projectInfo.lxr}}</p>
      <p>联系电话：{{projectInfo.lxdh}}</p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getNeedsDetail } from '../../api/announcement'
import { getPropertyType } from '../../api/exchange'
import { getRegionList } from '../../api/projectManage'
import { split } from '../../js/utils/util'
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      suggests: [],
      tableData: [],
      projectInfo: {},
      proTypes: [],
      type: '',
      regionOptions: [],
      region: '',
      dialogVisible: false
    }
  },
  created() {
    this.initData()
  },
  mounted() {

  },
  computed: {
    ...mapGetters(['access_token'])
  },
  methods: {
    async initPerporty() {
      // 请求详情数据
      let res = await getPropertyType();
      let {status, data} = res;
      if(status) {
        this.proTypes = data;
        this.formatterType(this.projectInfo.cqlxbh);
      }
    },
    formatterType(val) {
      let t = this.proTypes.filter(item => {return item.bh == val})[0].lx;
      this.type = t;
    },
    async getRegions() {
      // 获取信息
      let res = await getRegionList();
      let { status,data } = res;
      if(status) {
        let temp = data;
        this.regionOptions = split(temp);
        this.formatterRegion();
      }
    },
    formatterRegion() {
      this.regionOptions.map(item => {
        if(item.xzqbh == this.projectInfo.xzqbh) {
          this.region = item.xzqmc;
        } else {
          // console.log(item.list,'list')
          item.list && item.list.map(item2 => {
            if(item2.xzqbh == this.projectInfo.xzqbh) {
              this.region = item.xzqmc + ' / ' + item2.xzqmc
            }
          })
        }
      })
      return this.region;
    },
    handleRowClass({row, rowIndex}) {
      // 行樣式
      if(rowIndex % 2 === 0) {
        return  'odd'
      }
    },
    goback() {
      this.$router.go(-1);
    },
    async initData() {
      // 获取详情
      let params = {
        xqbh: this.$route.query.c
      }
      let res = await getNeedsDetail(params);
      let {status, data} = res;
      if(status) {
        this.projectInfo = data[0];
        this.initPerporty();
        this.getRegions();
      }
    },
    showPhone() {
      // 立即查看联系方式
      if(this.access_token) {
        // 已登录展示信息
        this.dialogVisible = true;
      } else {
        // 未登录
         this.$confirm('您未登录，不可查看联系方式！是否登录？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$router.push({name: 'login'});
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });
        });
      }
    }
  }
}
</script>

<style lang="less" scoped>
.demand-detail {
  width: 1200px;
  margin: 0 auto;
  background: #fff;
  text-align: left;
  padding:27px 62px 337px 62px;
  .s-title {
    padding: 0;
    height: 58px;
    line-height: 58px;
    position: relative;
  }
  .goback {
    position: absolute;
    right: 74px;
    top: 0;
    color:  #00a0e9;
    cursor: pointer;
  }
  .demand {
    font-family: MicrosoftYaHei;
    font-size: 14px;
    letter-spacing: 0px;
    color: #333333;
    margin: 0;
  }
  .detail {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 45px;
        height: 361px;
    .left {
      width: 674px;
      height: 100%;
      border-radius: 5px;
      border: solid 1px #d8d8d8;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      .item-con {
        width: 50%;
        height: calc(100% - 38px);
        margin: 20px 0 20px 18px;
        border-right: 1px solid #d8d8d8;
        .item {
          width: 239px;
          height: 87px;
          border-bottom: 1px solid #d8d8d8;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          line-height: 28px;
        }
        .item:first-child {
          display: inline-block;
        }
        .item:nth-child(2) {
          height: 135px;
        }
        .item:last-child {
          border-bottom: none;
          align-items: flex-end;
        }
        p {
          margin: 0;
        }
        .key {
          color: #979797;
        }
        .el-button {
          width: 79px;
          height: 31px;
          border-radius: 5px;
          padding: 0 12px;
        }
      }
      .item-con:first-child {
        padding-right: 64px;
      }
      .item-con:last-child {
        padding-left: 64px;
      }
    }
    .right {
      margin-left: 61px;
      padding-bottom: 10px;
      height: calc(100% - 73px);
      min-width: 400px;
      .docs {
        height: 100%;
        margin-bottom: 8px;
        overflow-y: auto;
        .docs-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          line-height: 40px;
          color: #333333;
          padding-right: 8px;
          img {
            margin-right: 9px;
          }
        }
      }
    }
  }
  .nearby {
    margin-top: 82px;
  }
}
</style>
